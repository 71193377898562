import React from 'react'
import './indexpageCss/IndexAbout.css';
import { NavLink } from 'react-router-dom';
import IndexTopClient from './IndexTopClient';
const IndexAbout = () => {
  return (
    <section className="indexaboutSection animate__tada">
    <div className="innerIndexAbout">
        <div className="inedeaboutfirst">
        <h1>We host the #1 rated team building and offsite events for in-person, hybrid & remote teams.</h1>
        <NavLink to="/about">Read More <i className="fa-solid fa-arrow-right-long"></i></NavLink>
         <IndexTopClient/>
            {/* <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolore, saepe consequatur culpa, commodi earum, repellat sequi esse corrupti aperiam quo et amet? Eligendi deserunt corrupti nobis aliquid, accusamus totam qui ipsam explicabo iusto voluptatum. Sit facere, laborum vel autem delectus labore deleniti eveniet magnam consectetur? Autem deserunt repellat, sequi, at suscipit aliquid asperiores architecto, mollitia itaque numquam aut beatae. In corporis veniam magnam quisquam delectus, odit illum? Deleniti, qui magnam modi quod quaerat vel dolores reprehenderit iste ipsam corrupti dolore doloremque, temporibus et sed in dolor, repellendus sit minima nisi adipisci? Fugit quaerat vitae accusamus facilis quidem nesciunt, exercitationem magnam.</p> */}
            
        </div>
        <div className="inedeaboutSecound">
             <div className="aboutinnerimage">
                    <img src="image/about-icon.png" alt="" />
                </div>
            {/* <div className="indexAboutImage1">
                <div className="aboutinner1">
                    <img src="image/index-about.jpg" alt="" />
                </div>
                <ul className="aboutinner2">
                    <li><i className="fa-solid fa-check"></i> Friendly Team</li>
                    <li><i className="fa-solid fa-check"></i> Unique Scenario</li>
                    <li><i className="fa-solid fa-check"></i> Briliant Idea</li>
                </ul>
            </div>
            <div className="indexAboutImage2">
                <div className="inneraboutimgbox">
                    <img src="image/index-about.jpg" alt="" />
                </div>
            </div> */}
        </div>
    </div>
</section>

  )
}

export default IndexAbout
