import React from 'react';
import { NavLink } from 'react-router-dom';
import './AboutPageCss/AboutPageInfo.css';
const AboutPageInfo = () => {
  return (
    <section className="aboutinfoSection" >
    <div className="inneraboutinfoSection">
        <div className="leftAboutInfo">
            <h2>At CYJ, we specialize in bringing your events to life, be it social gatherings, corporate functions, or weddings. </h2>
            <p id="subabt">Our dedicated team meticulously plans and executes every detail to ensure seamless and unforgettable experiences. </p>
            <p>From concept development to logistics coordination, we handle it all with professionalism and creativity. We collaborate closely with clients to understand their vision and exceed their expectations. With a focus on quality, innovation, and personalized service, CYJ is your partner in crafting memorable events that leave a lasting impression. Trust us to transform your ideas into reality and make your event truly exceptional. To let you know how we work, CYJ shares a brief plan about their perfect work ethics!</p>
                <NavLink to="/contact">Let's Talk!</NavLink>
        </div>
        <div className="rightAboutInfo">
            <div className="innerrightAboutInfo">
                <div className="firstinfoimgbox animatab">
                    <img src="image/image5.jpg" alt="" />
                </div>
                <div className="secoundinfoimgbox">
                    <div className="innersecundimg animatab">
                        <img src="image/image7.jpg" alt="" />
                    </div>
                    <div className="innersecundimg animatab">
                        <img src="image/image4.jpg" alt="" />
                    </div>
                </div>
                <div className="thirdinfoimgbox">
                    <NavLink to="/service" className="innerThirdtext animatab">
                        <i className="fa-solid fa-gears"></i>
                        <h3>Our Service</h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia fugiat repellat repellendus. Deleniti, maxime tempore.</p>
                    </NavLink>
                </div>
            </div>

        </div>
    </div>
</section>

  )
}

export default AboutPageInfo
