import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import '../IndexPage/indexpageCss/IndexService.css';
import PageBanner from '../../components/PageBanner';

const AllServices = () => {

    const [serdta, setServdata]=useState([]);
    useEffect(()=>{
        const servicedata = async()=>{
            try{
                const result = await fetch('https://backend.corporateeventplanner.in/api/cyj-service');
                const data = await result.json();
                setServdata(data);
            }catch(error){
                console.log(error);
            }
        }
        servicedata();
    }, []);


  return (
<>
    <PageBanner image="image/image5.jpg" heading="All Services" pageLink="/all-service" />
     <section className="serviceSection" style={{ 
        backgroundColor:"rgb(46, 7, 63)" 
      }}>
    <div className="Heading">
        <span className="topheading">Corporate</span>
        <div className="sbheadings">
            <p>Lorem ipsum dolor</p>
            <h2>Services</h2>
            <p>Lorem ipsum dolor</p>
        </div>
    </div>
    <div className="innerServiceSection">

        <div className="servicebox">

            {/* service Cards Start */}
           {/* service Cards Start */}
           {
    serdta
    .filter((i)=>i.status === 1)
    .map((serItem, index) => (
        <div className="serviceCards" key={index}>
            {serItem.image
                .filter(i => i.icon !== undefined)  // Filter out undefined icons
                .map((i, iIndex) => (  // Map over the filtered array
                    <div className="serviceimage" key={iIndex}>
                        <img 
                            src={`https://backend.corporateeventplanner.in/storage/service_gallery/${i.icon}`} 
                            alt={serItem.service} 
                        />

<div className="serviceText">
                <h3>{serItem.service}</h3>
                <p>{serItem.small_description}</p>
                <NavLink to={"../"+serItem.service_url}>Read More</NavLink>
            </div>
                    </div>
                ))
            }
            
          
        </div>
    ))
}

           

           {/* service Cards end */}

        </div>
    </div>
   
</section>
</>
  )
}

export default AllServices
