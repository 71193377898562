import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './indexpageCss/IndexService.css';

const IndexService = () => {
    // const [showCard, setShowCard] = useState(10);
    // const [rotate, setRotate] = useState(false);

    // const handleSlideButtonClick = () => {
    //     setShowCard(prevShowCard => prevShowCard === 10 ? document.querySelectorAll(".serviceCards").length : 10);
    //     setRotate(!rotate);
    // };

    // useEffect(() => {
    //     myserviceCards(showCard);
    // }, [showCard]);

    // const myserviceCards = (x) => {
    //     let serviceCardsShow = document.querySelectorAll(".serviceCards");
    //     for (let i = 0; i < serviceCardsShow.length; i++) {
    //         if (i < x) {
    //             serviceCardsShow[i].style.display = "block";
    //         } else {
    //             serviceCardsShow[i].style.display = "none";
    //         }
    //     }
    //     // Toggle rotation
    //     document.querySelector(".servsarro").style.transform = rotate ? "rotate(-180deg)" : "rotate(0deg)";
    // };

    const [serdta, setServdata]=useState([]);
    useEffect(()=>{
        const servicedata = async()=>{
            try{
                const result = await fetch('https://backend.corporateeventplanner.in/api/cyj-service');
                const data = await result.json();
                setServdata(data);
            }catch(error){
                console.log(error);
            }
        }
        servicedata();
    }, []);

// if(document.querySelectorAll(".serviceCards").length <= 6){
//     document.querySelector("#serviceSlideButton").style.display="none";
// }else{
//     document.querySelector("#serviceSlideButton").style.display="block";
// }

  return (
    <section className="serviceSection" >
    <div className="innerServiceSection">

    <div className="Heading">
        <span className="topheading">Corporate</span>
        <div className="sbheadings">
            {/* <p>Lorem ipsum dolor</p> */}
            <h2>Services</h2>
            {/* <p>Lorem ipsum dolor</p> */}
        </div>
    </div>
        <div className="servicebox">

            {/* service Cards Start */}
            {
    serdta
    .filter((i)=>i.status === 1)
    .map((serItem, index) => (
        <Link to={serItem.service_url} className="serviceCards" key={index}>
            {serItem.image
                .filter(i => i.icon !== undefined)  // Filter out undefined icons
                .map((i, iIndex) => (  // Map over the filtered array
                    <div className="serviceimage" key={iIndex}>
                        <img 
                            src={`https://backend.corporateeventplanner.in/storage/service_gallery/${i.icon}`} 
                            alt={serItem.service} 
                        />

<div className="serviceText">
                <h3>{serItem.service}</h3>
                <p>{serItem.small_description}</p>
                <Link to={serItem.service_url}>Read More</Link>
            </div>
                    </div>
                ))
            }
            
          
        </Link>
    ))
}

           

           {/* service Cards end */}

        </div>
        {/* <div id="serviceSlideButton" onClick={handleSlideButtonClick}>View All Service <i className="fa-solid fa-arrow-up-long servsarro"></i></div> */}
    </div>
    
</section>



  )
}

export default IndexService;
