import React, { useEffect, useState } from 'react';
import PageBanner from '../../components/PageBanner';
import './LocationPageCss/Location.css';
import { Link } from 'react-router-dom';
import MetaTag from '../../components/MetaTag';
const India = () => {

    const [locationData, setLocationData] = useState([]);
        
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchApiData = async () => {
            try {
                const result = await fetch('https://backend.corporateeventplanner.in/api/cyj-location');
                const data = await result.json();
                setIsLoading(false);
                setLocationData(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchApiData();
    }, []);



    const [ldta, setLdata] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
          try {
            const results = await Promise.all(
            locationData.map(async (item) => {
                try {
                  const response = await fetch(
                    `https://backend.corporateeventplanner.in/api/cyj-location-other-data/${item.id}`
                  );
                  const result = await response.json();
                  return {
                    location_id: item.id,
                    thumbnailImg: result,
                  };
                } catch (error) {
                  console.error(`Failed to fetch data for id ${item.id}:`, error);
                  return null;
                }
              })
            );
            setLdata(results.filter((result) => result !== null));
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
    
        if (locationData.length > 0) {
          fetchData();
        }
      }, [locationData]);
      


    let lokdta = null;

    if (isLoading) {
        lokdta = <p>Loading...</p>;
    } else {
       
        lokdta = locationData
        .filter(item => item.country === 'India')
        .map((item, index) => (
            <Link to={`../${item.location_url}`} className="alllokcards" key={index}>
                <div className="imgallLok">
                {ldta
                    .filter((i) => i.location_id === item.id)
                    .flatMap((i) => i.thumbnailImg)
                    .flatMap((y) => y.ThumbnailImage)
                    .map((x, idx) => (
                      <img
                        key={idx} // Use index as key here
                        src={`https://backend.corporateeventplanner.in/storage/location-gallery/${x.location_thumbnail}`}
                        alt={item.location}
                      />
                    ))}
                </div>
                <div className="lokallhead">
                    <h3>{item.location}</h3>
                    <p>{item.small_description}</p>
                    {/* Use Link component properly */}
                    <Link to={`../${item.location_url}`}>View</Link>
                </div>
            </Link>
        ));
    }



  return (
    <>
    <MetaTag noindex={true} titles="India title" metakeywords="India keywords in gurgaon" metaDescriptions="India Description 1" hrefLinks={window.location.href}/>
    <PageBanner image="image/image5.jpg" bannerheading="India Tours" heading="India Tours" pageLink="/india" />
            <section className="allLocationpageSection"
              
            >
                <div className="Heading">
                    <span className="topheading">All</span>
                    <div className="sbheadings">
                        <p>Lorem ipsum dolor</p>
                        <h2>India</h2>
                        <p>Lorem ipsum dolor</p>
                    </div>
                </div>
                <div className="allLocationpageBox">
                    {lokdta}
                </div>
            </section>
        </>
  )
}

export default India
