import React, { useEffect, useState } from 'react';
import './LocationPageCss/Locationvideo.css';

function Locationvideo(props) {
        const [isOpen, setIsOpen] = useState(false);
        const [dta, setData] = useState([]);
        const openVideo = () => {
          setIsOpen(true);
        };
      
        const closeVideo = () => {
          setIsOpen(false);
        };


       const firstId = props.data.length > 0 ? props.data[0].id : null;
       useEffect(()=>{
        fetch(`https://backend.corporateeventplanner.in/api/cyj-location-other-data/${firstId}`)
          .then((response)=>response.json())
          .then((result)=>setData(result))
          .catch((error)=>console.log(error))
        },[firstId]);
        
        
        const videoframe = () => {
          const urls = dta.flatMap(i => 
            i.RecentClip.map(a => a.RecentClipUrl)
          );        
          return urls.length > 0 ? urls[0] : null; // Return the first URL or null if no URLs
        };

        const images = () => {
          const imgArray = dta.flatMap(i => 
            i.RecentClip.map(a => a.RecentClipImage)
          );
          const imgString = imgArray.join(', ');
          return imgString;
        };
                
        const darkcolor = props.data.length > 0 ? props.data[0].darkColor : null;
       
  return (
    <div>
    <div className="locationdetail-page-head">
      <h5 style={{color:`${darkcolor}`}} >what we do</h5>
      <h4>Our Recent Clip</h4>
    </div>
    
    <div className="video-section"
      style={{
        backgroundImage: `url(${
          images() ? `https://backend.corporateeventplanner.in/storage/location-gallery/${images()}` : 'image/thumbnail2.jpg'
        })`,
      }}
      >
      <div className="background-image" >
        <button className="play-button" onClick={openVideo}>▶</button>
      </div>

      {isOpen && (
  <div className="video-popup">
    <div className="video-container">
      <span className="close-button" onClick={closeVideo}>&times;</span>
      <iframe 
        width="100%" 
        height="600px" 
        src={videoframe() || "https://www.youtube.com/embed/iL7_q7lTeH0?si=wUzlJmHWbaZPmXCb"} 
        title="YouTube video player" 
        frameBorder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
        allowFullScreen
      ></iframe>
    </div>
  </div>
)}



    </div>

    </div>
  )
}

export default Locationvideo
