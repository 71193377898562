import React, { useEffect, useState } from 'react';
import PageFrom from '../../components/PageFrom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-creative';
import 'swiper/css/effect-cube';
import { Autoplay, EffectCube } from 'swiper/modules';

const ServiceRightSection = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const response = await fetch('https://backend.corporateeventplanner.in/api/cyj-service-slider');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        if (isMounted) {
          setData(result);
          setLoading(false);
        }
      } catch (error) {
        if (isMounted) {
          setError(error.message);
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="rightSection">
      <div className="rightServiceSlidBanner">
        <h2>Trending</h2>
        <Swiper
          grabCursor={true}
          effect="cube"
          loop={true}
          cubeEffect={{
            shadow: true,
            slideShadows: true,
            shadowOffset: 20,
            shadowScale: 0.94,
          }}
          autoplay={{
            delay: 1000,
            disableOnInteraction: false,
          }}
          speed={1500}
          modules={[EffectCube, Autoplay]}
          className="mySwiper innerrightServiceSlidBanner"
        >
          {data.map((img) => (
            <SwiperSlide className="rightserslidimgb" key={img.id || img.serviceimage}>
              <a href={img.link}>
                <img src={`https://backend.corporateeventplanner.in/storage/service_gallery/${img.serviceimage}`} alt={img.link} />
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <PageFrom />
    </div>
  );
}

export default ServiceRightSection;
